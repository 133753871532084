html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes Appdisplay-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.half-col {
  max-width: 50% !important;
}

.word-break {
  word-break: break-word;
}

.no-overflow {
  overflow: unset !important;
}

.jumbotron {
  background-color: white !important;
}

.code {
  font-family: monospace !important;
}

#navigation li.dropdown-item a.custom-dropdown-link {
  color: rgba(0, 0, 0, 0.9) !important;
  padding: 2px 0 !important;
}
